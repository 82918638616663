@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;600;700&display=swap');
@import url('https://cdn.jsdelivr.net/gh/openlayers/openlayers.github.io@master/en/v6.5.0/css/ol.css');
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Public Sans', sans-serif;
}
body b {
  font-weight: bold;
}
body.root {
  overflow: hidden;
}

body .ol-overlaycontainer-stopevent .ol-search {
  background: transparent;
  padding: 0;
  position: absolute;
  top: auto;
  right: .5rem;
  bottom: calc(5rem + 42px);
  left: auto;
}

body .ol-overlaycontainer-stopevent .ol-search.hide {
  display: none;
}

body .ol-overlaycontainer-stopevent .ol-control {
  background: transparent;
  border-radius: 0;
  padding: 0;
  position: absolute;
  top: auto;
  right: .5rem;
  bottom: 2.5rem;
  left: auto;
}

body .ol-overlaycontainer-stopevent .ol-control.gc-search {
  top: .5rem;
  right: .5rem;
  left: auto;
}

body .ol-overlaycontainer-stopevent .ol-control button:focus, body .ol-overlaycontainer-stopevent .ol-control button:hover {
  background-color: white;
}

body .ol-overlaycontainer-stopevent button {
  width: 32px;
  height: 32px;
  align-items: center;
  background-color: #FFF;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #002340;
  border-radius: 50%!important;
  color: #000;
  cursor: pointer;
  display: flex;
  font-size: 9px;
  font-weight: normal;
  justify-content: center;
  line-height: 12px;
  margin: 0 auto;
  outline: 0;
  transition: background-color 0.2 ease-out;
  -o-transition: background-color 0.2 ease-out;
  -moz-transition: background-color 0.2 ease-out;
  -webkit-transition: background-color 0.2 ease-out;
}
body .ol-overlaycontainer-stopevent button + button {
  margin-top: 8px;
}

body .ol-overlaycontainer-stopevent button.square {
  border-radius: 0;
}
body .ol-overlaycontainer-stopevent button:hover {
  background-color: #f4f5f4;
}

body .ol-overlaycontainer-stopevent button.ol-zoom-in {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.5 1.34334c0-.27615-.22386-.500003-.5-.500003s-.5.223853-.5.500003v5.15685H1.34315c-.27615 0-.500004.22386-.500004.5s.223854.5.500004.5H6.5V12.657c0 .2762.22386.5.5.5s.5-.2238.5-.5V7.50019h5.1569c.2761 0 .5-.22386.5-.5s-.2239-.5-.5-.5H7.5V1.34334z' fill='%23002340'/%3e%3c/svg%3e");
}
body .ol-overlaycontainer-stopevent button.ol-zoom-out {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='2' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.6569 1.00012H1.34315' stroke='%23002340' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
}
body .ol-overlaycontainer-stopevent button.ol-search-button {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23002340' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.47 11c0-4.142 3.36-7.5 7.507-7.5 4.148 0 7.509 3.358 7.509 7.5a7.47 7.47 0 01-2.105 5.208.503.503 0 00-.208.207 7.488 7.488 0 01-5.195 2.085c-4.148 0-7.509-3.358-7.509-7.5zm13.15 6.362a8.482 8.482 0 01-5.643 2.138C6.28 19.5 2.47 15.695 2.47 11s3.81-8.5 8.508-8.5c4.699 0 8.509 3.805 8.509 8.5a8.463 8.463 0 01-2.158 5.657l3.994 3.99a.5.5 0 01-.707.707l-3.995-3.992z' fill='inherit'%3E%3C/path%3E%3C/svg%3E");
  background-size: 18px;
}
body .ol-overlaycontainer-stopevent button.ol-layer-change {
  background-image: url('https://deliverytestdata.blob.core.windows.net/deliverytest/gmaps-icon.jpg');
  border-radius: 0;
}
body .ol-overlaycontainer-stopevent button.ol-filter {
  background-size: 12px;
  background-image: url('https://deliverytestdata.blob.core.windows.net/deliverytest/filter.svg');
  border-radius: 0;
}

body .ol-overlaycontainer-stopevent .ol-scale-line{
  background-color: rgba(255, 255, 255, 0.9);
  right: .5rem;
  left: auto;
}

body .ol-overlaycontainer-stopevent .ol-scale-line .ol-scale-line-inner{
  border-color: rgba(31, 33, 43, 0.97);
  color: rgba(31, 33, 43, 0.97);
}

body .project--menu--tooltip {
  width: 250px;
  background-color: rgba(31, 33, 43, 0.97) !important;
  border: 1px solid #2e2e2e !important;
  opacity: 0 !important;
  padding: 0 !important;
  z-index: 100;
}
body .project--menu--tooltip.show {
  opacity: 1 !important;
}
body .project--menu--tooltip:after {
  border-bottom-color: rgba(31, 33, 43, 0.97) !important;
}
body .tooltipcover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
body .layer--tooltip--options {
  width: 180px;
  background-color: rgba(255, 255, 255, 0.96) !important;
  border: none !important;
  opacity: 0 !important;
  margin-right: 15px !important;
  padding: 0 !important;
  z-index: 100;
}
body .layer--tooltip--options.show {
  opacity: 1 !important;
}
body .layer--tooltip--options:after {
  border-bottom: 10px solid transparent !important;
  border-right-color: rgba(255, 255, 255, 0.96) !important;
  border-right-width: 8px;
  border-top: 10px solid transparent !important;
  left: -6px !important;
  top: 48.5% !important;
}

body .rc-tooltip-placement-top .rc-tooltip-arrow {
  border-top-color: rgba(255,255,255,.95);
  bottom: -4px;
}
body .rc-tooltip-placement-right .rc-tooltip-arrow {
  border-right-color: rgba(255,255,255,.95);
  display: none;
}
/* body .rc-tooltip-content .rc-tooltip-arrow{
  border-right-color: rgba(255,255,255,.95);
} */

body .rc-tooltip-content .rc-tooltip-inner{
  min-height: 0;
  border-color: rgb(187,187,187);
  box-shadow: rgba(0, 0, 0, .2) 0 1px 3px;
  padding: 0;
}

body::-webkit-scrollbar {
  width:16px;
  background-color: #FFF;
}

body::-webkit-scrollbar-track {
  background-color: #fff;
}

body::-webkit-scrollbar-thumb {
  background-color:#babac0;
  border-radius:16px;
  border:4px solid #fff;
}

body::-webkit-scrollbar-button {display:none}

strong {
  font-weight: 700;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

/* AOIModeButtonWrapper.css */
.icon-text-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

}

.icon-text-vertical .text {
  font-size: 0.8em; /* Smaller text size */
  margin-top: 4px; /* Space between icon and text */
}

.icon-container svg {
  display: block;
}